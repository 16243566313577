import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from "react-icons/bs";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import emailjs from "emailjs-com";
import "./ContactForm.css";

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_bdfl0ie", "template_6mll9lc", form.current, "CLcHWAKSemVMd1_sU")
            .then(
                (result) => {
                    console.log(result.text);
                    setButtonText("Message Sent");

                    // Reset form and button text after 3 seconds
                    setTimeout(() => {
                        e.target.reset();
                        setButtonText("Submit");
                    }, 3000);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className="contactform">
            <section className="container">
                <div>
                    <div className="footer_get_touch_inner">
                        <div className="get_form_inner">
                            <div className="get_form_inner_text">
                                <h3>Get In Touch</h3>
                                <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="inputs">
                                    <input type="text" name="name" placeholder="Your Name" required pattern="[A-Za-z\s]+" title="Only letters and spaces allowed" /><br />
                                    <input type="email" name="email" placeholder="Your Email" required /><br />
                                    <input type="tel" name="phone" placeholder="Your Phone" required pattern="[0-9]{10,}" title="Enter a valid phone number" /><br />
                                    <input type="text" name="subject" placeholder="Subject" required /><br />
                                </div>
                                <div>
                                    <textarea name="message" placeholder="How can we help?" cols="30" rows="10" required></textarea>
                                    <input type="submit" value={buttonText} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <div className="contact-cards-container">
                <div className="get_form_inner2 card1">
                    <h2>CALL US</h2>
                    <p><AiOutlinePhone /> <a href="tel:3234521224">+1 (323) 452-1224</a></p>
                </div>

                <div className="get_form_inner2 card2">
                    <h2>EMAIL</h2>
                    <p><AiOutlineMail /> Info@PalmSpringsRehab.biz</p><br />
                </div>

                <div className="get_form_inner2 card3">
                    <h2>LOCATION</h2>
                    <p><BsFillPinMapFill /> 68487 E Palm Canyon Dr, STE 44, Cathedral City CA 92234</p>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
