import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import MH from '../components/MH/MH'
import MenuMentalHealth from "../components/menus/MenuMentalHealth";
import ContactForm from "../components/contact/ContactForm";



const MHealth = () => {
  return (
    <>
    <Navbar /> 
    <MH />
    <MenuMentalHealth />
    <ContactForm/>
    <Footer />
     </>
  )
}

export default MHealth